.pre-line{
	white-space: pre-line !important;
}

.error-field {
  border-color: red !important;
  border-style: solid;
  border-width: 1px;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open{
	z-index: 10000;
}

.z-10{
	position: relative;
	z-index: 2;
	font-size: 14px;
}

.z-10 div div{
	padding-top: 0.3rem;
  padding-bottom: 0.2rem;
}

.z-101 div div{
	padding-top: 0.3rem;
  padding-bottom: 0.2rem;
}

.z-102 div div{
	padding-top: 0.3rem;
  padding-bottom: 0.2rem;
}

.z-9{
	z-index: 1 !important;
}

.z-auto{
	z-index: auto;
}

.z-1{
	z-index: 100;
}

.z-101{
	z-index: 105 !important;
	font-size: 14px;
}

.z-102{
	z-index: 110 !important;
	font-size: 14px;
}

.z-9{
	position: relative;
	z-index: 10 !important;
}

.z-8{
	position: relative;
	z-index: 9 !important;
}

.z-7{
	position: relative;
	z-index: 8 !important;
}

.z-6{
	position: relative;
	z-index: 7 !important;
}

.MuiPaper-root .MuiPaper-elevation {
    z-index: 1!important;
}

.material-icons-round{
	font-size: 1.5rem !important;
}

.bg-white{
	background-color: #ffffff;
	max-width: 10rem;
	border-radius: 0.5rem;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.border-red > div{
	border: 1px solid red;
	border-radius: 0.35rem;
}

.font-red{
	font-size: 0.8rem;
	color: red;
}

.border1{
	border: 1px solid #cccccc;
	border-radius: 0.5rem;
}

hr {
  border: 1px solid #e8e8e8;
}

h6{
	line-height: 1!important;
	padding-bottom: 0;
}
.line-h{
	line-height: 1;
	font-size: 0.8rem;
	color: #7b809a;
	padding-bottom: 0.1rem;
}
.anim{
	transition: max-width, 0.35s ease-in-out, width, 0.35s ease-in-out;
}


#canv * {
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 24px;
  line-height: 1.5rem;
}

.pos-rel {
  position: relative !important;
  width: 637px;
  height: 1015px;
  border-radius: 0 !important;
}

.cont{
	margin-left: auto;
  margin-right: auto;
  width: 637px;
  border: 1px solid #000000;
}

.cont-hide{
  position: absolute;
  top: -200rem;
}

.qr {
  position: absolute;
  bottom: 18rem;
  left: 1.5rem;
  width: 10rem;
  border: 1px solid red;
}

.avatar {
  position: absolute;
  left: calc(50% - 5.5rem);
  top: 14.25rem;
  width: 11rem;
  height: 14rem;
  border: 1px solid
}

.nombre-acreditado {
  position: absolute;
  top: 32rem;
  left: 13.25rem;
  width: 390px;
}

.curso {
  position: absolute;
  top: 36rem;
  left: 13.25rem;
  width: 390px;
}

.fecha-finalizacion {
  position: absolute;
  top: 47.5rem;
  left: 13.25rem;
  width: 390px;
}

.no-certificacion {
  position: absolute;
  top: 51.5rem;
  left: 13.25rem;
  width: 390px;
}

.bold {
  font-weight: bold;
}

.desarrollo {
  position: absolute;
  bottom: 5.9rem;
  left: 13rem;
  color: #ffffff;
  width: 390px;
}

.humano {
  position: absolute;
  bottom: 4rem;
  left: 13rem;
  color: #ffffff;
}

.text {
  position: absolute;
  top: 3rem;
  left: 3rem;
  z-index: 100;
}

img.pos-rel {
    height: 100%;
    width: 100%;
}

.containerExamples{
  justify-content: center;
  align-items: center;
  display: flex;
  flex-basis: content;
}

.containerExamples div{
  float: left;
  display: flex;
  justify-content: center;
}

.containerExamplesSubir div{
  float: left;
  display: flex;
  justify-content: start;
}

.file-select {
  position: relative;
  display: inline-block;
}

.file-select::before {
  display: flex;
  justify-content: center;
  align-items: end;
  font-size: 12px;
  border-radius: 3px;
  font-weight: bold;
  content: 'Seleccionar';
  position: absolute;
  width: 150px;
  height: 85px;
  background-size: 86px;
  background-image: url(https://byspel.com/wp-content/uploads/2017/01/upload-cloud.png);
  background-repeat: no-repeat;
  background-position-y: 5px;
  background-position-x: center;
}

.file-select input[type="file"] {
  opacity: 0;
  width: 150px;
  height: 85px;
  display: inline-block;
  cursor: pointer;
}

#src-ine::before {
  content: 'INE solo frontal';
}

#src-foto::before {
  content: 'Fotografía';
}

#src-foto-subir::before {
  content: 'Subir';
}

#src-logo::before {
  content: 'Cargar logo';
}

/* // Table */

.textTable{
  font-size: 12px;
  color: #344767
}

.subTable{
  margin-top: 10px;
  border-width: 1px 1px 1px 5px;
  border-style: solid;
  border-radius: 80px;
  border-color: #e1e1e1 #e1e1e1 #e1e1e1 #344767;
  margin-left: 30px;
}

.select-custom{
  border-color: #d3d3d3;
  border-width: 1px;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
}

.correct-option{
  font-size: 14px;
  background-color: #d8edf5;
  padding: 2px;
  border-radius: 5px;
}

.input-inline{
  border-width: 0;
  border-bottom: 1px;
  border-style: solid;
  border-color: #dedede;
  padding: 5px;
  width: 80%;
}

.input-inline-bold{
  font-size: 18;
  font-weight: bold;
}

.wrapperClassName{
  border-width: 1px;
  border-style: solid;
  border-color: #888;
  padding: 5px;
}

.eye-password{
  position: absolute;
  right: 30px;
  top: 210px;
  text-transform: uppercase;
  cursor: pointer;
  padding: 5px 10px;
}

.filter-text{
  font-size: 17px !important;
  border-bottom: 2px solid #0091fb !important;
  width: 80% !important;
}

.data-table-extensions-filter{
  width: 80% !important;
}

.data-table-extensions > .data-table-extensions-filter > .icon {
  width: 26px !important;
  background-size: cover !important;
}

@media (min-width: 481px) and (max-width: 767px) {
  .shareLink{
    padding-top: 5px !important;
    padding-left: 0px !important;
    width: 100% !important;
  }

  .headerShareLink{
    display: initial !important;
    margin-top: 0px !important;
    margin-left: 0px !important;
  }

  .headerTitleShareLink{
    padding-top: 0px !important;
  }

  .headerButtomShareLink{
    padding-top: 15px !important;
    justify-content: center !important;
  }
}